/*
* Main JS file 
* 
* @author       Honza Michalička <honza.michalicka@gmail.com>
* @copyright    2014 Honza Michalička
*/

$(document).ready(function(){

	// SCROLL
	/*
	$("#header").headroom({
        tolerance: 5,	// scroll tolerance in px before state changes
        offset : 400  // vertical offset in px before element is first unpinned
    });
    */

    $('.show-all').on('click', function () {
        $('.children').toggle();
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('[data-toggle="popover"]').popover()


    $('select:not(.custom-select2)').select2({
        minimumResultsForSearch: 10
    });

    $('.datepicker').datepicker({
        format: 'dd.mm.yyyy',
        autoclose: true,
        weekStart: 1,
        orientation: 'top',
        todayHighlight: true
    });

    // Animate clicks to anchor links
    $("a[href^='#']").bind('click', function(e) {
        e.preventDefault();
        var hash = this.hash;
        $('html,body').animate({
            scrollTop: $(hash).offset().top
            }, 300, function(){
            // when done, add hash to url (default click behaviour)
            window.location.hash = hash;
        });
    });

if ($('.fix-header').size()>0) {
    initDataTable();

    function initDataTable(){
        $('footer').remove(); // page footer not necessary - just saving some height

        $('.fix-header').DataTable({
            fixedHeader: {
                headerOffset: calcHeaderHeight()
            },
            fixedColumns: {
                leftColumns: 1
            },
            dom: "Bfrtip",
            scrollY: calcDataTableHeight(),
            scrollX: true,
            scrollCollapse: true,
            paging: false,
            buttons: ['colvis'],
            bPaginate: false,
            bFilter: false,
            ordering: false,
            bInfo: false
        });
    }

    function calcDataTableHeight() {
        return Math.round($(window).height() - calcHeaderHeight() - $('.timeline thead').height()-20);
    }

    function calcHeaderHeight() {
        return Math.round($('header').height() + $('.timeline-navbar').height() - 1);
    }
}

    function detailTableToggle($el) {
        if ($el.data('detail-table-state') == 'closed') {
            $el.data('detail-table-state', 'open');
            $el.parent().children("tbody, tfoot").show();
            $el.parent().removeClass('detail-table-state-close');
            $el.parent().addClass('detail-table-state-open');
        } else {
            $el.data('detail-table-state', 'closed');
            $el.parent().children("tbody, tfoot").hide();
            $el.parent().removeClass('detail-table-state-open');
            $el.parent().addClass('detail-table-state-close');
        }
    };

    $("[data-detail-table-toggle]").each(function(i, el) {
        var $el = $(el);

        $el.click(function(e) {

            var $this = $(e.currentTarget);

            detailTableToggle($this);

            e.stopPropagation();
            e.preventDefault();

            return false;
        });

        detailTableToggle($el);
    });

    $(".wysiwyg").wysihtml5({toolbar: {
        "fa": true
    }});
});
    